import { leapLogin } from '@leapfinance/auth-kit';
import Image from 'next/image';
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import useVerloopChatbot from 'hooks/useVerloopChatbot';
import useLogin, { FlowType } from 'hooks/useLogin';
import { trackClick } from 'scripts/segment';
import {
  contactInfo,
  menaAccountLink,
  menaDropDown,
  menaLoggInStickyLinks,
  navbarLinks,
  singleLinks,
} from 'DataMapper/Common/navbar';
import { internationalLoginObject } from 'DataMapper/Common/loginData';

const Navbar = () => {
  const [isMenuOpen, setMenuVisiblity] = useState(false);
  const [openVerloopChatBot] = useVerloopChatbot();
  const handleMenuOpen = () => setMenuVisiblity((prev) => !prev);

  const { initLogin } = useLogin(FlowType.COMMON);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(leapLogin.isLogin());
  }, []);

  const handleLogin = (contentName: string) => {
    trackClick('NA', {
      contentFormat: 'Button',
      widgetName: 'Website Header',
      contentName,
    });
    initLogin({ ...internationalLoginObject }, () => {
      if (leapLogin.isLogin()) setIsLoggedIn(true);
      window.location.reload();
    });
  };

  const handleEvent = async (
    contentName: string,
    e: MouseEvent<HTMLElement>,
    isTargetBlank: boolean,
  ) => {
    const currentTarget = e.currentTarget;
    e.preventDefault();

    try {
      await trackClick('NA', {
        contentFormat: 'Button',
        widgetName: 'Website Header',
        contentName,
      });
    } catch (err) {
    } finally {
      if ((currentTarget as HTMLAnchorElement)?.href) {
        window.open(
          (currentTarget as HTMLAnchorElement).href,
          isTargetBlank ? '_blank' : '_self',
          isTargetBlank ? 'noopener noreferrer' : '',
        );
      }
    }
  };

  return (
    <>
      <div className="bg-white shadow-md w-full right-0 left-0 fixed top-0 z-10 font-poppins">
        <div className="max-w-[1400px] header md:m-auto p-4 xl:px-0 flex justify-between items-center border-b border-[#E2E2E8]">
          <div className="flex items-center gap-9">
            <a
              href={process.env.DOMAIN_NAME}
              onClick={(e) => {
                handleEvent('LS Logo', e, false);
              }}
              className="flex justify-center items-center"
            >
              <Image
                src={
                  'https://leapscholar.com/assets/company_logo/new-header-logo.svg'
                }
                height={37}
                width={191}
                alt="leap-scholar-logo"
                className="inline-block"
              />
            </a>
          </div>

          <div className="md:flex items-center hidden">
            {isLoggedIn &&
              menaDropDown.map((data) => (
                <DropdownMenu
                  key={data.id}
                  isLoggedIn={isLoggedIn}
                  handleEvent={handleEvent}
                  openVerloopChatBot={openVerloopChatBot}
                  heading={data.heading}
                  values={data.value}
                  uiType="button"
                />
              ))}

            {!isLoggedIn && (
              <div className="flex items-center gap-4 pl-3">
                {['Sign In', 'Sign Up'].map((el, i) => (
                  <button
                    key={i}
                    onClick={() => handleLogin(el)}
                    className={`text-sm font-semibold p-[10px] w-[80px] border border-primary rounded ${
                      i === 1 ? 'text-white bg-primary' : 'text-primary'
                    }`}
                  >
                    {el}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 md:gap-6 md:hidden">
            <div className="flex items-center justify-center">
              <img
                src={
                  isMenuOpen
                    ? 'https://ik.imagekit.io/onsnhxjshmp/close-btn.svg'
                    : 'https://ik.imagekit.io/onsnhxjshmp/hamburger.svg'
                }
                height={24}
                width={24}
                onClick={() => {
                  handleMenuOpen();
                  trackClick('NA', {
                    contentFormat: 'Button',
                    widgetName: 'Website Header',
                    contentName: 'Header Hamburger',
                  });
                }}
                alt="hamburger-menu"
              />
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <>
          <div
            className={`fixed overflow-auto bottom-0 top-[70px] z-10 w-full bg-white font-poppins ${
              isLoggedIn ? 'pb-[180px]' : 'pb-24'
            }`}
          >
            {/* Mandatory links */}
            {[navbarLinks['Study Abroad'], navbarLinks['IELTS']].map(
              (el, i) => (
                <SubMenuComponent
                  isLoggedIn={isLoggedIn}
                  {...el}
                  key={i}
                  handleEvent={handleEvent}
                />
              ),
            )}

            {singleLinks.map(
              (el, i) =>
                !(el.text === 'About' && isLoggedIn) && (
                  <a
                    href={el.link}
                    onClick={(e) => handleEvent(el.text, e, false)}
                    key={i}
                    className="flex items-center gap-[10px] p-4 border-b border-[#E2E2E8]"
                  >
                    <Image
                      src={el.icon}
                      height={20}
                      width={20}
                      alt="Study abroad"
                    />
                    <p className="text-sm">{el.text}</p>
                  </a>
                ),
            )}

            {/* Logged in Section */}
            {isLoggedIn &&
              [menaAccountLink['Account']].map((el, i) => (
                <SubMenuComponent
                  isLoggedIn={isLoggedIn}
                  handleEvent={handleEvent}
                  {...el}
                  key={i}
                />
              ))}

            {/* Contact section */}
            {contactInfo.map((el, i) => (
              <a
                href={el.link}
                key={i}
                onClick={(e) => handleEvent(el.contentName, e, false)}
                className="flex items-center gap-[10px] p-4 border-b border-[#E2E2E8]"
              >
                <Image
                  src={el.icon}
                  height={20}
                  width={20}
                  alt="Study abroad"
                />
                <p className="text-sm">{el.text}</p>
              </a>
            ))}
          </div>

          {/* Bottom Sticky buttons */}
          <div
            className={`fixed bg-white font-poppins flex items-center gap-4 ${
              isLoggedIn && 'flex-col'
            } w-full bottom-0 z-20 shadow-4 border-t border-[#CBCAD5] py-5 px-4`}
          >
            {isLoggedIn
              ? menaLoggInStickyLinks.map((el) => (
                  <a
                    key={el.link}
                    href={el.link}
                    onClick={(e) => handleEvent(el.text, e, false)}
                    className={`text-sm font-semibold p-[10px] flex items-center justify-between border border-primary rounded w-full text-white bg-primary`}
                  >
                    <span className="flex items-center gap-2">
                      <Image
                        height={16}
                        width={16}
                        alt="Leap Logo"
                        src={
                          'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/leap-logo-white.svg'
                        }
                      />
                      {el.text}
                    </span>

                    <Image
                      height={16}
                      width={16}
                      alt="Arrow right"
                      src={
                        'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/arrow-white.svg'
                      }
                    />
                  </a>
                ))
              : ['Sign In', 'Sign Up'].map((el, i) => (
                  <button
                    key={i}
                    onClick={() => handleLogin(el)}
                    className={`text-sm font-semibold p-[10px] border border-primary rounded w-[50%] ${
                      i === 1 ? 'text-white bg-primary' : 'text-primary'
                    }`}
                  >
                    {el}
                  </button>
                ))}
          </div>
        </>
      )}

      <style jsx global>{`
        body {
          overflow: ${isMenuOpen ? 'hidden' : 'auto'};
        }

        .header {
          max-width: 1200px;
        }
      `}</style>
    </>
  );
};

interface SubMenuComponentProps {
  icon: string;
  text: string;
  links: {
    text: string;
    link: string | null;
    isTargetBlank?: boolean;
    nestedSubmenu?: Array<{ text: string; link: string }>;
    breadcrumb?: string;
    hideInLoggedInState?: boolean;
  }[];
  handleEvent: (
    contentName: string,
    e: MouseEvent<HTMLElement>,
    isTargetBlank: boolean,
  ) => void;
  isLoggedIn: boolean;
}

const SubMenuComponent: React.FC<SubMenuComponentProps> = (props) => {
  const { handleEvent, isLoggedIn } = props;
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [isNestedSubMenuOpen, setNestedSubmenuOpen] = useState(false);

  const [nestedSubmenuData, setNestedSubmenuData] = useState<{
    breadcrumb: string;
    links: { text: string; link: string }[];
  }>({ breadcrumb: '', links: [] });

  return (
    <div className={isSubmenuOpen ? 'bg-[#E2E6FA] pb-6' : 'bg-white'}>
      <div
        onClick={() => setSubmenuOpen((prev) => !prev)}
        className="flex items-center justify-between p-4 border-b border-[#E2E2E8]"
      >
        <div className="flex items-center gap-[10px]">
          <Image
            src={props.icon}
            height={20}
            width={20}
            alt="Study abroad"
            className="brightness-0"
          />
          <p className="text-sm">{props.text}</p>
        </div>
        <Image
          className={`brightness-0 ${
            isSubmenuOpen && 'rotate-180 transition-all'
          }`}
          src={
            'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
          }
          height={20}
          width={20}
          alt="Open"
        />
      </div>
      {isSubmenuOpen && (
        <div className="mx-4 bg-white rounded-lg shadow-[0px_2px_4px_0px_rgba(16,24,40,0.12)]">
          {props.links.map((el) => {
            return isLoggedIn ? (
              !el.hideInLoggedInState && (
                <div>
                  {el.nestedSubmenu ? (
                    <div
                      onClick={() => {
                        setNestedSubmenuOpen((prev) => !prev);
                        setNestedSubmenuData({
                          breadcrumb: el.breadcrumb || '',
                          links: el.nestedSubmenu || [],
                        });
                      }}
                      className="px-4 py-3 border-b border-[#E2E2E8] text-sm flex justify-between"
                    >
                      <p> {el.text}</p>
                      <Image
                        className="brightness-0 -rotate-90"
                        src={
                          'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
                        }
                        height={20}
                        width={20}
                        alt="Open"
                      />
                    </div>
                  ) : (
                    el.link && (
                      <a
                        href={el.link}
                        {...(el.isTargetBlank
                          ? { target: '_blank', rel: 'noopener noreferrer' }
                          : {})}
                        onClick={(e) =>
                          handleEvent(el.text, e, !!el.isTargetBlank)
                        }
                        className="px-4 py-3 border-b border-[#E2E2E8] text-sm block"
                      >
                        {el.text}
                      </a>
                    )
                  )}
                </div>
              )
            ) : (
              <div>
                {el.nestedSubmenu ? (
                  <div
                    onClick={() => {
                      setNestedSubmenuOpen((prev) => !prev);
                      setNestedSubmenuData({
                        breadcrumb: el.breadcrumb || '',
                        links: el.nestedSubmenu || [],
                      });
                    }}
                    className="px-4 py-3 border-b border-[#E2E2E8] text-sm flex justify-between"
                  >
                    <p> {el.text}</p>
                    <Image
                      className="brightness-0 -rotate-90"
                      src={
                        'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
                      }
                      height={20}
                      width={20}
                      alt="Open"
                    />
                  </div>
                ) : (
                  el.link && (
                    <a
                      href={el.link}
                      {...(el.isTargetBlank
                        ? { target: '_blank', rel: 'noopener noreferrer' }
                        : {})}
                      onClick={(e) =>
                        handleEvent(el.text, e, !!el.isTargetBlank)
                      }
                      className="px-4 py-3 border-b border-[#E2E2E8] text-sm block"
                    >
                      {el.text}
                    </a>
                  )
                )}
              </div>
            );
          })}
        </div>
      )}

      {isNestedSubMenuOpen && (
        <NestedSubMenu
          nestedSubmenuLinks={nestedSubmenuData.links}
          setNestedSubmenuOpen={setNestedSubmenuOpen}
          breadcrumb={nestedSubmenuData.breadcrumb}
          handleEvent={handleEvent}
        />
      )}
    </div>
  );
};

interface NestedSubMenuProps {
  setNestedSubmenuOpen: Dispatch<SetStateAction<boolean>>;
  nestedSubmenuLinks: { text: string; link: string }[];
  breadcrumb?: string;
  handleEvent: (
    contentName: string,
    e: MouseEvent<HTMLElement>,
    isTragetBlank: boolean,
  ) => void;
}

const NestedSubMenu: React.FC<NestedSubMenuProps> = (props) => {
  const { handleEvent } = props;

  return (
    <div className="fixed overflow-auto bottom-0 top-[70px] left-0 z-10 w-full bg-white font-poppins">
      <div className="bg-[#E2E6FA] p-4 border-b border-[#E2E2E8]">
        <div className="flex items-center gap-2 leading-5 text-sm font-semibold pb-4">
          <Image
            onClick={() => props.setNestedSubmenuOpen(false)}
            className="-rotate-[135deg] brightness-0"
            src={
              'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-up-right_LFouZ5Mw8r.svg'
            }
            height={20}
            width={20}
            alt="Back"
          />
          <p className="text-[#77768E] text-xs font-medium">IELTS Exam</p>
          <span>/</span>
          <p>{props.breadcrumb}</p>
        </div>

        <div className="bg-white rounded-lg shadow-[0px_2px_4px_0px_rgba(16,24,40,0.12)]">
          {props.nestedSubmenuLinks.map((el, i) => (
            <a
              key={i}
              href={el.link}
              onClick={(e) => handleEvent(el.text, e, false)}
              className="px-4 py-3 border-b border-[#E2E2E8] text-sm block"
            >
              {el.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

interface DropdownMenuProps {
  uiType: 'button' | 'link';
  heading: string;
  values: Array<{
    text: string;
    link: string | null;
    isTargetBlank?: boolean;
    hideInLoggedInState?: boolean;
    nestedSubmenu?: Array<{ text: string; link: string }>;
  }>;
  isMobile?: boolean;
  openVerloopChatBot: (customRecipi: string) => void;
  handleEvent: (
    contentName: string,
    e: MouseEvent<HTMLElement>,
    isTargetBlank: boolean,
  ) => void;
  isLoggedIn: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const { heading, values, openVerloopChatBot, handleEvent, isLoggedIn } =
    props;
  const [subMenuText, setSubMenuText] = useState('');
  const ref: any = useRef();

  const [nestedSubmenuData, setNestedSubmenuData] = useState<
    { text: string; link: string }[]
  >([]);

  const handleSubmenuOpen = (toOpen: string) => () => {
    setSubMenuText((prev) => (prev === toOpen ? '' : toOpen));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (ref.current?.contains(e.target as Node)) {
        return;
      }
      setSubMenuText('');
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  return (
    <>
      <div
        ref={ref}
        onClick={handleSubmenuOpen(heading)}
        className={`flex flex-col gap-6 relative`}
      >
        {props.uiType === 'link' ? (
          <div className={`flex items-center gap-2 py-2 px-3`}>
            <p
              className={`font-bold text-primary text-base leading-6 cursor-pointer`}
            >
              {heading}
            </p>

            <Image
              className={`${
                subMenuText === heading && 'rotate-180 transition-all'
              }`}
              src={
                'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
              }
              height={20}
              width={20}
              alt="Open"
            />
          </div>
        ) : (
          <div
            className={`flex items-center gap-2 rounded py-2 px-3 ml-3 ${
              heading === 'Dashboard'
                ? 'bg-primary text-white'
                : 'bg-white text-primary border border-primary'
            }`}
          >
            {heading === 'My Account' ? (
              <Image
                src={
                  'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg'
                }
                height={20}
                width={20}
                alt="Account"
              />
            ) : (
              <p className={`font-semibold text-sm leading-6 cursor-pointer`}>
                {heading}
              </p>
            )}

            <Image
              className={`${
                subMenuText === heading && 'rotate-180 transition-all'
              }`}
              src={
                heading === 'Dashboard'
                  ? 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/dropdown-arrow-white.svg'
                  : 'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
              }
              height={20}
              width={20}
              alt="Open"
            />
          </div>
        )}

        {subMenuText === heading ? (
          <>
            <div
              className={`flex py-3 rounded-b-lg flex-col shadow-4 ${
                heading === 'My Account'
                  ? 'min-w-[120px] -left-[38%]'
                  : ' min-w-[216px] -left-[4px]'
              } bg-white gap-2 absolute top-[59px]`}
            >
              {values.map((el, i) => {
                return isLoggedIn ? (
                  !el.hideInLoggedInState && (
                    <div
                      className="hover:bg-primary hover:text-white transition-all"
                      key={i}
                    >
                      {el?.nestedSubmenu ? (
                        <>
                          <div
                            id="hover-menu"
                            onMouseOver={() => {
                              setNestedSubmenuData(el.nestedSubmenu || []);
                            }}
                            className="px-4 py-3 text-sm flex justify-between cursor-pointer relative"
                          >
                            <p>{el.text}</p>
                            <Image
                              className="brightness-0 -rotate-90"
                              src={
                                'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
                              }
                              height={20}
                              width={20}
                              alt="Open"
                            />

                            {/* Nested submenu */}
                            <div
                              id="nested-submenu"
                              className={`flex py-3 w-[236px] ${
                                i === 0 ? 'rounded-br-lg' : 'rounded-r-lg'
                              } flex-col shadow-4 bg-white gap-2 absolute -right-[235px] -top-[12px]`}
                            >
                              {nestedSubmenuData.map((el, i) => {
                                return (
                                  <div
                                    className="hover:bg-primary hover:text-white transition"
                                    key={i}
                                  >
                                    {el.link && (
                                      <a
                                        href={el.link}
                                        onClick={(e) =>
                                          handleEvent(el.text, e, false)
                                        }
                                        className="px-4 py-3 text-sm block"
                                      >
                                        {el.text}
                                      </a>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      ) : (
                        el.link && (
                          <a
                            {...(el.isTargetBlank
                              ? { target: '_blank', rel: 'noopener noreferrer' }
                              : {})}
                            href={el.link}
                            onClick={(e) =>
                              handleEvent(el.text, e, !!el.isTargetBlank)
                            }
                            className="px-4 py-3 text-sm block"
                          >
                            {el.text}
                          </a>
                        )
                      )}
                    </div>
                  )
                ) : (
                  <div
                    className="hover:bg-primary hover:text-white transition-all"
                    key={i}
                  >
                    {el?.nestedSubmenu ? (
                      <>
                        <div
                          id="hover-menu"
                          onMouseOver={() => {
                            setNestedSubmenuData(el.nestedSubmenu || []);
                          }}
                          className="px-4 py-3 text-sm flex justify-between cursor-pointer relative"
                        >
                          <p>{el.text}</p>
                          <Image
                            className="brightness-0 -rotate-90"
                            src={
                              'https://ik.imagekit.io/onsnhxjshmp/SEO_project/pillar-pages/arrow-down-nav_RgHK4gTT3.svg'
                            }
                            height={20}
                            width={20}
                            alt="Open"
                          />

                          {/* Nested submenu */}
                          <div
                            id="nested-submenu"
                            className={`flex py-3 w-[236px] ${
                              i === 0 ? 'rounded-br-lg' : 'rounded-r-lg'
                            } flex-col shadow-4 bg-white gap-2 absolute -right-[235px] -top-[12px]`}
                          >
                            {nestedSubmenuData.map((el, i) => {
                              return (
                                <div
                                  className="hover:bg-primary hover:text-white transition"
                                  key={i}
                                >
                                  {el.link && (
                                    <a
                                      href={el.link}
                                      onClick={(e) =>
                                        handleEvent(el.text, e, false)
                                      }
                                      className="px-4 py-3 text-sm block"
                                    >
                                      {el.text}
                                    </a>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      el.link && (
                        <a
                          {...(el.isTargetBlank
                            ? { target: '_blank', rel: 'noopener noreferrer' }
                            : {})}
                          href={el.link}
                          onClick={(e) =>
                            handleEvent(el.text, e, !!el.isTargetBlank)
                          }
                          className="px-4 py-3 text-sm block"
                        >
                          {el.text}
                        </a>
                      )
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>

      <style jsx>{`
        #nested-submenu {
          display: none;
        }

        #hover-menu:hover > #nested-submenu {
          display: flex;
          color: black;
        }
      `}</style>
    </>
  );
};

export default Navbar;
