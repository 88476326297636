import {
  CountryDto,
  CountryDialingCodeMapping,
  AuthToken,
  countryData,
} from '@leapfinance/auth-kit';
import {
  cookiesConstant,
  deleteCookie,
  getCookie,
  saveCookie,
} from 'utils/cookie-utiils';
import { AxiosResponse } from 'axios';
import { IpInfoSchema } from 'Interfaces/common/ipStackSchema';
import { CountryCode } from 'DataMapper/CountryCode';
import api from 'services/api';

export const checkIfItsServer = (): boolean => {
  return typeof window === 'undefined';
};

export const checkIfMENAUser = (
  userCountry: string | null | undefined,
): boolean => {
  if (!checkIfItsServer()) {
    const country =
      userCountry || getCookie(null, cookiesConstant.USER_COUNTRY);
    if (MIDDLE_EAST_COUNTRIES.find((item) => item === country)) return true;
  }
  return false;
};
export const MIDDLE_EAST_COUNTRIES = [
  CountryDto.BAHRAIN,
  CountryDto.KUWAIT,
  CountryDto.OMAN,
  CountryDto.QATAR,
  CountryDto.SAUDI,
  CountryDto.UAE,
  CountryDto.ALGERIA,
  CountryDto.EGYPT,
  CountryDto.IRAN,
  CountryDto.IRAQ,
  CountryDto.ISRAEL,
  CountryDto.JORDAN,
  CountryDto.LEBANON,
  CountryDto.LIBYA,
  CountryDto.MOROCCO,
  CountryDto.SYRIA,
  CountryDto.TUNISIA,
  CountryDto.PALESTINE,
];
export const MIDDLE_EAST_COUNTRIES_IN_FORM = [
  CountryDto.UAE,
  CountryDto.SAUDI,
  CountryDto.BAHRAIN,
  CountryDto.KUWAIT,
  CountryDto.QATAR,
  CountryDto.OMAN,
];

export const getCountryFromCookies = () => {
  try {
    return getCookie(null, cookiesConstant.IP_COUNTRY_KEY) || null;
  } catch (e) {
    return null;
  }
};

export const setCountryInCookies = (countryName: string) => {
  try {
    deleteCookie(null, cookiesConstant.IP_COUNTRY_KEY);
    saveCookie(null, cookiesConstant.IP_COUNTRY_KEY, countryName);
  } catch (e) {
    console.log('fail to save country');
  }
};

const getCountryUserInfo = async (): Promise<string | undefined> => {
  try {
    const userInfoRes: AxiosResponse<IpInfoSchema> = await api.get(
      'api/userIpInfo',
    );
    if (userInfoRes.data) {
      const userInfo = userInfoRes.data;
      return (
        CountryDialingCodeMapping[userInfo?.location?.calling_code] ||
        CountryCode.INDIA
      );
    }
  } catch (e) {
    return CountryCode.INDIA;
  }
};

export const getUserCountry = async (
  auth: AuthToken | undefined,
): Promise<string> => {
  try {
    const saveCountry = getCountryFromCookies();
    if (auth?.isValid && auth.userCountry) {
      return auth.userCountry;
    } else if (saveCountry) {
      return saveCountry;
    } else {
      const ipCountry = await getCountryUserInfo();
      return ipCountry || CountryCode.INDIA;
    }
  } catch (e) {
    return CountryCode.INDIA;
  }
};

export const getCountryFromCountryCode = (code: string) => {
  let country: string | null = null;
  Object.keys(countryData).forEach((item) => {
    if (countryData[item].code === code) {
      country = item;
    }
  });
  return country;
};
