import React, { FunctionComponent, useEffect, useState } from 'react';
import useLogin, { FlowType } from 'hooks/useLogin';
import Navbar from '@leapfinance/frontend-commons/components/LeapScholar/Navbar';
import { leapLogin } from '@leapfinance/auth-kit';
import { trackEventAsync } from 'scripts/segment';
import { loggedInNav, loggedOutNav } from 'utils/navbarUtils';
import { internationalLoginObject } from 'DataMapper/Common/loginData';
import { navRoutes } from 'page_routes';
import useVerloopChatbot from 'hooks/useVerloopChatbot';
import { useRouter } from 'next/router';

const NavbarWrapper: FunctionComponent = () => {
  const { initLogin } = useLogin(FlowType.COMMON);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openVerloopChatBot] = useVerloopChatbot();
  const router = useRouter();

  const handleVerloop = () => {
    openVerloopChatBot('wB3pEWHKGe9Twu9bD');
  };
  useEffect(() => {
    setIsLoggedIn(leapLogin.isLogin());
  }, []);

  const handleSegmentEvents = async (title: string) => {
    await trackEventAsync('Click', {
      contentFormat: 'Button',
      widgetName: 'Website Header',
      contentName: title,
      pageName: 'NA',
    });
  };

  const getNavData = () => {
    if (isLoggedIn) return { ...loggedInNav(handleVerloop) };
    else
      return {
        ...loggedOutNav,
        buttons: [
          {
            title: 'Sign in',
            buttonType: 'SECONDARY',
            handleClick: () =>
              initLogin({ ...internationalLoginObject }, () => {
                if (leapLogin.isLogin()) setIsLoggedIn(true);
                window.location.reload();
              }),
          },
          {
            title: 'Sign Up',
            buttonType: 'PRIMARY',
            handleClick: () =>
              initLogin({ ...internationalLoginObject }, () => {
                if (leapLogin.isLogin()) setIsLoggedIn(true);
                window.location.reload();
              }),
          },
        ],
      };
  };

  const getSelectedTitle = () => {
    if (router.pathname.includes('/profile/counselling')) return 'Counselling';
    if (router.pathname.includes('/test-prep/dashboard')) return 'IELTS Prep';
    return '';
  };

  return (
    <Navbar
      {...getNavData()}
      isLoggedIn={isLoggedIn}
      logoLink={navRoutes.HOMEPAGE}
      handleSegmentEvents={handleSegmentEvents}
      selectedTitle={getSelectedTitle()}
    />
  );
};

export default NavbarWrapper;
