import { FunctionComponent, useEffect, useState } from 'react';
import Footer from 'components/Common/Footer';
import useLocation from 'hooks/useLocation';
import { checkIfMENAUser } from 'utils/countryUtils';
import useLogin from 'hooks/useLogin';
import MenaNavbar from 'components/Common/Layout/MenaNavbar';
import NavbarWrapper from 'components/Common/NavbarWrapper';

interface Props {
  children: any;
  hideFooter?: boolean;
}

const Layout: FunctionComponent<Props> = ({ children, hideFooter }) => {
  const { userCountry } = useLocation();
  const { loginStatus } = useLogin();
  const [isMeUser, setIsMeUser] = useState<boolean>(false);

  useEffect(() => {
    if (checkIfMENAUser(userCountry)) {
      setIsMeUser(true);
    }
  }, [userCountry, loginStatus]);

  return (
    <div>
      {isMeUser ? <MenaNavbar /> : <NavbarWrapper />}
      <div className="md:pt-[76px] pt-[72px]">{children}</div>
      {!hideFooter && !isMeUser && <Footer />}
    </div>
  );
};
export default Layout;
