import useDevice from 'hooks/useDevice';
import useLogin from 'hooks/useLogin';
import Image from 'next/image';
import { navRoutes } from 'page_routes';
import React, { FunctionComponent } from 'react';
import { trackClick } from 'scripts/segment';
import { pageName } from 'utils/events-utils';
import { loginFlowType } from 'utils/url_utils';
import FooterLink from 'components/Common/Footer/FooterLinks';
import FooterHtmlSiteMap from 'components/Common/Footer/FooterHtmlSiteMap';

const footerLinks = {
  countries: [
    {
      country: 'USA',
      link: '/usa',
    },
    {
      country: 'UK',
      link: '/uk',
    },
    {
      country: 'Canada',
      link: '/canada',
    },
    {
      country: 'Australia',
      link: '/australia',
    },
    {
      country: 'Ireland',
      link: '/ireland',
    },
  ],
  topUniversities: [
    {
      text: 'Top Universities in USA',
      link: '/usa/university/top-universities-in-usa',
    },
    {
      text: 'Top Universities in Canada',
      link: '/canada/university/top-universities-in-canada',
    },
    {
      text: 'Top Universities in UK',
      link: '/uk/university/top-universities-in-uk',
    },
  ],
  offerings: [
    {
      text: 'Free IELTS Masterclass',
      link: '/ielts-free-online-coaching',
    },
    {
      text: 'Talk to a counsellor',
      link: '/counsellors',
    },
    {
      text: 'Twinning Programs',
      link: 'https://advantage.leapscholar.com',
    },
    {
      text: 'Cost Calculator',
      link: '/cost-calculator',
    },
    {
      text: 'Blog',
      link: 'https://leapscholar.com/blog',
    },
    {
      text: 'Study Abroad Knowledge Center',
      link: 'https://leapscholar.com/blog/study-abroad-knowledge-center-sitemap/',
    },
    {
      text: 'Events',
      link: '/events',
    },
  ],
  exams: [
    {
      text: 'IELTS',
      link: 'https://leapscholar.com/exams/ielts?flow=footer',
    },
    {
      text: 'TOEFL',
      link: 'https://leapscholar.com/exams/toefl?flow=footer',
    },
    {
      text: 'SAT',
      link: 'https://leapscholar.com/exams/sat?flow=footer',
    },
    {
      text: 'PTE',
      link: 'https://leapscholar.com/exams/pte?flow=footer',
    },
    {
      text: 'GRE',
      link: 'https://leapscholar.com/exams/gre?flow=footer',
    },
    {
      text: 'GMAT',
      link: 'https://leapscholar.com/exams/gmat?flow=footer',
    },
  ],
  company: [
    {
      text: 'About Us',
      link: '/about-us',
    },
    {
      text: 'Careers',
      link: 'https://leapfinance.freshteam.com/jobs',
    },
    {
      text: 'Leap in the news!',
      link: 'https://press.leapscholar.com',
    },
  ],
  products: [
    {
      text: 'LeapFinance',
      link: 'https://leapfinance.com',
    },
    {
      text: 'LevelUp by Leap',
      link: 'https://levelup.leapfinance.com',
    },
    {
      text: 'LeapAdvantage',
      link: 'https://advantage.leapscholar.com',
    },
    {
      text: 'IELTS Prep by LeapScholar',
      link: '/ielts-preparation-online/crash_course',
    },
  ],
  socialMedia: [
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/youtube.svg',
      link: 'https://www.youtube.com/c/leapscholar',
      contentName: 'Youtube',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/twitter.svg',
      link: 'https://twitter.com/leapscholar',
      contentName: 'Twitter',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/linkedin.svg',
      link: 'https://in.linkedin.com/company/leap-scholar',
      contentName: 'Linkedin',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/instagram.svg',
      link: 'https://www.instagram.com/leapscholar',
      contentName: 'Instagram',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/facebook.svg',
      link: 'https://www.facebook.com/leapscholar',
      contentName: 'Facebook',
    },
    {
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/telegram-icon.svg',
      link: 'https://t.me/leap_abroad',
      contentName: 'Telegram',
    },
  ],
  partners: [
    {
      link: 'https://yocket.com',
      contentName: 'Yocket study abroad',
    },
    {
      link: 'https://geebeeworld.com',
      contentName: 'GEEBEE',
    },
  ],
};

const getWidget = (contentName?: string, widgetName?: string) => {
  return {
    contentName,
    widgetName,
    widgetFormat: 'Banner',
    contentFormat: 'Button',
  };
};

const Divider: FunctionComponent = () => (
  <div className="h-[1px] bg-[#686868] w-full my-6"></div>
);

const ContactSection: React.FC = () => {
  const { isMobile } = useDevice();

  return (
    <div className="flex flex-col gap-1 text-[#E8E8E8]">
      {!isMobile && <p className="font-bold text-[15px]">Contact us</p>}
      <a className="text-sm text-[#CDCDCD]" href="tel:08047184844">
        Call us on: (080)47184844 (Between 10AM to 7PM)
      </a>
      <a
        className="text-sm text-[#CDCDCD]"
        href="mailto:contact@leapscholar.com"
      >
        Mail us on: contact@leapscholar.com
      </a>
    </div>
  );
};

const Footer = () => {
  const { isMobile } = useDevice();
  const { handleSpotCounsellingFlow } = useLogin(loginFlowType());

  return (
    <>
      <footer
        className={`bg-[#1B1D6A] text-white ${
          isMobile ? 'py-5 px-[22px]' : 'p-[36px]'
        }`}
      >
        <div className={!isMobile ? 'wrapper' : ''}>
          <div className="flex flex-col gap-6">
            {/* logo */}
            <div className="flex items-center gap-2">
              <Image
                className="logo w-[200px]"
                loading="lazy"
                src="/assets/company_logo/new-footer-logo.svg"
                alt="logo"
                width={200}
                height={50}
              />
            </div>
            <div>
              <button
                onClick={() => {
                  if (handleSpotCounsellingFlow) handleSpotCounsellingFlow();
                  trackClick(
                    pageName.LeapScholarHomePage,
                    getWidget('Start your Journey', 'Footer'),
                  );
                }}
                className="mb-4 shadow-[0px_4px_20px_rgba(110,125,179,0.4)] text-center rounded w-[180px] bg-yellow-to-orange inline-block text-sm leading-6 font-bold py-3"
              >
                Start your Journey
              </button>
            </div>

            {/* contact-mobile */}
            {isMobile ? <ContactSection /> : null}

            {/* Social footerLinks */}
            <div className="flex flex-col gap-1 text-[#E8E8E8]">
              <p className="font-bold text-[15px]">Follow us on:</p>
              <div className="flex gap-2 mt-2">
                {footerLinks.socialMedia.map((element, index) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={element.link}
                  >
                    <Image
                      src={element.icon}
                      alt="social-icon"
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="flex-1">
            <span className="md:hidden">
              <Divider />
            </span>
            <FooterLink />
            <Divider />
            <div
              className={`grid ${
                !isMobile ? 'md:grid-cols-4' : 'grid-cols-2 gap-5 mt-8'
              }`}
            >
              {/* Countries */}
              <div className="flex flex-col gap-1 text-[#E8E8E8]">
                <p className="font-bold text-[15px]">Countries</p>
                {footerLinks.countries.map((element, index) => (
                  <a
                    key={index}
                    href={`${element.link}`}
                    className="text-sm text-[#CDCDCD]"
                  >
                    {element.country}
                  </a>
                ))}

                <div className="flex flex-col text-[#E8E8E8] mt-2">
                  <p className="font-bold text-[15px]">Top Universities</p>
                  {footerLinks.topUniversities.map((element, index) => (
                    <a
                      key={index}
                      href={`${element.link}`}
                      className="text-sm text-[#CDCDCD]"
                    >
                      {element.text}
                    </a>
                  ))}
                </div>
              </div>

              {/* Company */}
              <div className="flex flex-col gap-1 text-[#E8E8E8]">
                <p className="font-bold text-[15px]">Company</p>
                {footerLinks.company.map((element, index) => (
                  <a
                    className="text-sm text-[#CDCDCD]"
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={element.link}
                  >
                    {element.text}
                  </a>
                ))}

                {!isMobile && (
                  <div className="flex flex-col text-[#E8E8E8] mt-2">
                    <p className="font-bold text-[15px]">Our Partners</p>
                    {footerLinks.partners.map((element, index) => (
                      <a
                        className="text-sm text-[#CDCDCD]"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={element.link}
                      >
                        {element.contentName}
                      </a>
                    ))}
                  </div>
                )}
              </div>

              {/* Offerings */}
              <div className="flex flex-col gap-1 text-[#E8E8E8]">
                <p className="font-bold text-[15px]">Our Offerings</p>
                {footerLinks.offerings.map((element, index) =>
                  element.link === '/' ? (
                    <a className="text-sm text-[#CDCDCD]" key={index} href="/">
                      {element.text}
                    </a>
                  ) : (
                    <a
                      className="text-sm text-[#CDCDCD] w-[85%]"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={element.link}
                    >
                      {element.text}
                    </a>
                  ),
                )}
              </div>

              {/* Products */}
              <div className="flex flex-col gap-1 text-[#E8E8E8]">
                <p className="font-bold text-[15px]">Our Products</p>
                {footerLinks.products.map((element, index) => (
                  <a
                    className="text-sm text-[#CDCDCD]"
                    rel="noopener noreferrer"
                    target={
                      element.text === 'IELTS by Leap' ? '_self' : '_blank'
                    }
                    key={index}
                    href={element.link}
                  >
                    {element.text}
                  </a>
                ))}

                <p className="font-bold text-[15px] mt-2">Exam Information</p>
                {footerLinks.exams.map((element, index) => (
                  <a
                    className="text-sm text-[#CDCDCD]"
                    rel="noopener noreferrer"
                    target={
                      element.text === 'IELTS by Leap' ? '_self' : '_blank'
                    }
                    key={index}
                    href={element.link}
                  >
                    {element.text}
                  </a>
                ))}
              </div>
            </div>

            <Divider />

            <div>
              {/* Locations */}
              <div className={!isMobile ? 'grid md:grid-cols-4' : ''}>
                <div className="flex flex-col gap-1 text-[#E8E8E8]">
                  <p className="font-bold text-[15px]">
                    HQ- Bangalore &nbsp;(India)
                  </p>
                  <p
                    className={`text-[13px] text-[#CDCDCD] ${
                      !isMobile && 'max-w-[148px]'
                    }`}
                  >
                    163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru,
                    Karnataka 560102
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-[#E8E8E8]">
                  <p className="font-bold text-[15px]">
                    Singapore &nbsp;(International)
                  </p>
                  <p
                    className={`text-[13px] text-[#CDCDCD] ${
                      !isMobile && 'max-w-[148px]'
                    }`}
                  >
                    11 Collyer Quay #17-00 The Arcade, 049317, Singapore
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                <div className="flex flex-col gap-1 text-[#E8E8E8]">
                  <p className="font-bold text-[15px]">
                    UAE &nbsp;(International)
                  </p>
                  <p
                    className={`text-[13px] text-[#CDCDCD] ${
                      !isMobile && 'max-w-[148px]'
                    }`}
                  >
                    Office 1106, Arenco Tower, Sheikh Zayed Road, <br /> Media
                    City Dubai - 3087, UAE <br /> Contact Number: +971 501501220
                  </p>
                </div>

                {isMobile ? <Divider /> : null}

                {!isMobile ? <ContactSection /> : null}

                {/* Our Partners */}
                <div className="flex flex-col gap-1 text-[#E8E8E8] md:hidden">
                  <p className="font-bold text-[15px]">Our Partners</p>
                  {footerLinks.partners.map((element, index) => (
                    <a
                      className="text-sm text-[#CDCDCD]"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={element.link}
                    >
                      {element.contentName}
                    </a>
                  ))}
                </div>

                {/* Privacy */}
                <div className="flex flex-col gap-1 text-[#E8E8E8] pt-4 md:pt-0">
                  <a
                    href="/privacy"
                    className={`text-sm text-[#CDCDCD] inline-block`}
                  >
                    Privacy Policy
                  </a>
                  {/* Terms and Conditions */}
                  <a
                    className={`text-sm text-lightgray-1650 inline-block`}
                    href={navRoutes.TERM_AND_CONDITION}
                  >
                    Terms and Conditions
                  </a>
                  {/* Refund policy */}
                  <a
                    href={navRoutes.REFUND_POLICY}
                    className={`text-sm text-lightgray-1650 inline-block`}
                  >
                    Refund policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterHtmlSiteMap />
      </footer>

      {/*@ts-ignore*/}
      <style jsx>
        {`
          .logo {
            filter: brightness(0) invert(1);
            margin-left: -4px;
            height: 50px;
          }

          .wrapper {
            max-width: 1120px;
            margin: auto;
            display: flex;
            gap: 80px;
          }
        `}
      </style>
    </>
  );
};

export default Footer;
