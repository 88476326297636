export const loggedOutNav = {
  navData: [
    {
      title: 'Study Abroad',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/study-abroad.svg',
      subMenu: [
        {
          text: 'Study in USA',
          link: '/usa',
        },
        {
          text: 'Study in UK',
          link: '/uk',
        },
        {
          text: 'Study in Canada',
          link: '/canada',
        },
        {
          text: 'Study in Australia',
          link: '/australia',
        },
        {
          text: 'Study in Ireland',
          link: '/ireland',
        },
        {
          text: "Study in Germany",
          link: "/germany",
        },
        {
          text: 'Our Counsellors',
          link: '/counsellors',
        },
        {
          text: "Master's Programs",
          link: 'https://advantage.leapscholar.com/',
          isTargetBlank: true,
        },
        {
          text: 'Undergraduate Programs',
          link: 'https://advantage.leapscholar.com/for-undergrad',
          isTargetBlank: true,
        },
      ],
    },
    {
      title: 'IELTS',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/ielts.svg',
      subMenu: [
        {
          text: 'IELTS Courses',
          link: '/test-prep/dashboard',
        },
        {
          text: 'IELTS Booking Details',
          breadcrumb: 'Booking',
          nestedSubmenu: [
            {
              text: 'IELTS Dates',
              link: '/exams/ielts/dates',
            },
            {
              text: 'IELTS Fees',
              link: '/exams/ielts/fees',
            },
            {
              text: 'IELTS Centres',
              link: '/exams/ielts/centre',
            },
            {
              text: 'IELTS Registration',
              link: '/exams/ielts/registration',
            },
            {
              text: 'IELTS Slot Booking',
              link: '/exams/ielts/slot-booking',
            },
          ],
        },
        {
          text: 'IELTS Module Details',
          breadcrumb: 'Module',
          nestedSubmenu: [
            {
              text: 'IELTS Module Overview',
              link: '/exams/ielts/modules',
            },
            {
              text: 'Speaking Details',
              link: '/exams/ielts/modules/speaking-overview',
            },
            {
              text: 'Listening Details',
              link: '/exams/ielts/modules/listening-overview',
            },
            {
              text: 'Writing Details',
              link: '/exams/ielts/modules/writing-overview',
            },
            {
              text: 'Reading Details',
              link: '/exams/ielts/modules/reading-overview',
            },
          ],
        },
        {
          text: 'IELTS Practice Test',
          link: '/exams/ielts/practice-test',
        },
        {
          text: 'IELTS Exam Overview',
          link: '/exams/ielts',
        },
        {
          text: 'Free IELTS Masterclass',
          link: '/ielts-free-online-coaching',
        },
        {
          text: 'Important IELTS Articles',
          link: '/blog/category/ielts-preparation/',
          isTargetBlank: true,
        },
      ],
    },
    {
      title: 'Events',
      link: '/events',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/event.svg',
    },
    {
      title: "Blogs",
      link: "/blog/",
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/library_books.svg",
    },
    {
      title: 'About',
      link: '/about-us',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/about.svg',
    },
  ],
  mobileNavData: [
    {
      title: 'Study Abroad',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/study-abroad.svg',
      subMenu: [
        {
          text: 'Study in USA',
          link: '/usa',
        },
        {
          text: 'Study in UK',
          link: '/uk',
        },
        {
          text: 'Study in Canada',
          link: '/canada',
        },
        {
          text: 'Study in Australia',
          link: '/australia',
        },
        {
          text: 'Study in Ireland',
          link: '/ireland',
        },
        {
          text: "Study in Germany",
          link: "/germany",
        },
        {
          text: 'Our Counsellors',
          link: '/counsellors',
        },
        {
          text: "Master's Programs",
          link: 'https://advantage.leapscholar.com/',
        },
        {
          text: 'Undergraduate Programs',
          link: 'https://advantage.leapscholar.com/for-undergrad',
        },
      ],
    },
    {
      title: 'IELTS',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/ielts.svg',
      subMenu: [
        { text: 'IELTS Courses', link: '/test-prep/dashboard' },
        {
          text: 'IELTS Booking Details',
          breadcrumb: 'Booking',
          nestedSubmenu: [
            {
              text: 'IELTS Dates',
              link: '/exams/ielts/dates',
            },
            {
              text: 'IELTS Fees',
              link: '/exams/ielts/fees',
            },
            {
              text: 'IELTS Centres',
              link: '/exams/ielts/centre',
            },
            {
              text: 'IELTS Registration',
              link: '/exams/ielts/registration',
            },
            {
              text: 'IELTS Slot Booking',
              link: '/exams/ielts/slot-booking',
            },
          ],
        },
        {
          text: 'IELTS Module Details',
          breadcrumb: 'Module',
          nestedSubmenu: [
            {
              text: 'IELTS Module Overview',
              link: '/exams/ielts/modules',
            },
            {
              text: 'Speaking Details',
              link: '/exams/ielts/modules/speaking-overview',
            },
            {
              text: 'Listening Details',
              link: '/exams/ielts/modules/listening-overview',
            },
            {
              text: 'Writing Details',
              link: '/exams/ielts/modules/writing-overview',
            },
            {
              text: 'Reading Details',
              link: '/exams/ielts/modules/reading-overview',
            },
          ],
        },
        {
          text: 'IELTS Practice Test',
          link: '/exams/ielts/practice-test',
        },
        {
          text: 'IELTS Exam Overview',
          link: '/exams/ielts',
        },
        {
          text: 'Free IELTS Masterclass',
          link: '/ielts-free-online-coaching',
        },
        {
          text: 'Important IELTS Articles',
          link: '/blog/category/ielts-preparation/',
          isTargetBlank: true,
        },
      ],
    },
    {
      title: 'Events',
      link: '/events',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/event.svg',
    },
    {
      title: "Blogs",
      link: "/blog/",
      icon: "https://leapassets.s3.ap-south-1.amazonaws.com/navbar/library_books.svg",
    },
    {
      title: 'About',
      link: '/about-us',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/about.svg',
    },
    {
      title: '(080) 47184844',
      link: 'tel:+918047184844',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/call.svg',
    },
    {
      title: 'contact@leapscholar.com',
      link: 'mailto:contact@leapscholar.com',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/email.svg',
    },
  ],
};

export const loggedInNav = (handleVerloop: () => void) => ({
  navData: [
    {
      title: 'Counselling',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/study-abroad.svg',
      link: '/profile',
    },
    {
      title: 'IELTS Prep',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/ielts.svg',
      link: '/test-prep/dashboard',
    },
  ],
  buttons: [
    {
      title: 'Download App',
      buttonType: 'PRIMARY',
      buttonSubMenu: [
        {
          text: 'Counselling App',
          link: 'https://play.google.com/store/apps/details?id=com.leapscholar.app',
          isTargetBlank: true,
        },
        {
          text: 'IELTS Prep App',
          link: 'https://play.google.com/store/apps/details?id=com.knudge.ielts',
          isTargetBlank: true,
        },
      ],
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/download-app.svg',
    },
    {
      title: 'My Account',
      buttonType: 'SECONDARY',
      buttonSubMenu: [
        {
          text: 'Need Help',
          icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/need-help.svg',
          handleClick: () => handleVerloop(),
        },
        {
          text: 'Profile',
          link: '/profile',
        },
        {
          text: 'Log Out',
          link: '/api/logout',
        },
      ],
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg',
    },
  ],
  mobileNavData: [
    {
      title: 'Download App',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/download-app.svg',
      subMenu: [
        {
          text: 'Counselling App',
          link: 'https://play.google.com/store/apps/details?id=com.leapscholar.app',
          isTargetBlank: true,
        },
        {
          text: 'IELTS Prep App',
          link: 'https://play.google.com/store/apps/details?id=com.knudge.ielts',
          isTargetBlank: true,
        },
      ],
    },
    {
      title: 'My Account',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg',
      subMenu: [
        {
          text: 'Profile',
          link: '/profile',
        },
        {
          text: 'Log Out',
          link: '/api/logout',
        },
      ],
    },
    {
      title: 'Need Help',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/need-help.svg',
      handleClick: () => handleVerloop(),
    },
    {
      title: '(080) 47184844',
      link: 'tel:+918047184844',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/call.svg',
    },
    {
      title: 'contact@leapscholar.com',
      link: 'mailto:contact@leapscholar.com',
      icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/email.svg',
    },
  ],
});
