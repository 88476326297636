import { navRoutes } from 'page_routes';

export const navbarLinks = {
  'Study Abroad': {
    text: 'Study Abroad',
    links: [
      {
        text: 'Study in USA',
        link: '/usa',
        isTargetBlank: false,
      },
      {
        text: 'Study in UK',
        link: '/uk',
        isTargetBlank: false,
      },
      {
        text: 'Study in Canada',
        link: '/canada',
        isTargetBlank: false,
      },
      {
        text: 'Study in Australia',
        link: '/australia',
        isTargetBlank: false,
      },
      {
        text: 'Study in Ireland',
        link: '/ireland',
        isTargetBlank: false,
      },
      {
        text: "Study in Germany",
        link: "/germany",
        isTargetBlank: false,
      },
      {
        text: 'Our Counsellors',
        link: '/counsellors',
        isTargetBlank: false,
      },
      {
        text: 'Master’s Programs',
        link: 'https://advantage.leapscholar.com/',
        isTargetBlank: true,
      },
      {
        text: 'Undergraduate Programs',
        link: 'https://advantage.leapscholar.com/for-undergrad',
        isTargetBlank: true,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/study-abroad.svg`,
  },
  IELTS: {
    text: 'IELTS',
    links: [
      {
        text: 'IELTS Booking Details',
        link: null,
        breadcrumb: 'Booking',
        nestedSubmenu: [
          { text: 'IELTS Dates', link: '/exams/ielts/dates' },
          { text: 'IELTS Fees', link: '/exams/ielts/fees' },
          { text: 'IELTS Centres', link: '/exams/ielts/centre' },
          { text: 'IELTS Registration', link: '/exams/ielts/registration' },
          { text: 'IELTS Slot Booking', link: '/exams/ielts/slot-booking' },
        ],
      },
      {
        text: 'IELTS Module Details',
        link: null,
        breadcrumb: 'Module',
        nestedSubmenu: [
          { text: 'IELTS Module Overview', link: '/exams/ielts/modules' },
          {
            text: 'Speaking Details',
            link: '/exams/ielts/modules/speaking-overview',
          },
          {
            text: 'Listening Details',
            link: '/exams/ielts/modules/listening-overview',
          },
          {
            text: 'Writing Details',
            link: '/exams/ielts/modules/writing-overview',
          },
          {
            text: 'Reading Details',
            link: '/exams/ielts/modules/reading-overview',
          },
        ],
      },
      {
        text: 'IELTS Practice Test',
        link: '/exams/ielts/practice-test',
      },
      { text: 'IELTS Exam Overview', link: '/exams/ielts' },
      {
        text: 'Paid IELTS Courses',
        link: '/ielts-preparation-online',
        hideInLoggedInState: true,
      },
      {
        text: 'Free IELTS Masterclass',
        link: '/ielts-free-online-coaching',
        hideInLoggedInState: true,
      },
      {
        text: 'Important IELTS Articles',
        link: '/blog/category/ielts-preparation/',
        isTargetBlank: true,
      },
    ],
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/ielts.svg',
  },
  'Download App': {
    text: 'Download App',
    links: [
      {
        text: 'Counselling App',
        link: 'https://play.google.com/store/apps/details?id=com.leapscholar.app',
        isTargetBlank: true,
      },
      {
        text: 'IELTS Prep App',
        link: 'https://play.google.com/store/apps/details?id=com.knudge.ielts',
        isTargetBlank: true,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/download-app.svg`,
  },
  Account: {
    text: 'Account',
    links: [
      {
        text: 'Profile',
        link: '/profile?type=navbar',
        isTargetBlank: false,
      },
      {
        text: 'Log Out',
        link: navRoutes.LOGOUT,
        isTargetBlank: false,
      },
    ],
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg`,
  },
};

export const menaLoggInStickyLinks = [
  {
    text: 'IELTS Prep Dashboard',
    link: '/test-prep/dashboard/',
  },
];

export const menaAccountLink = {
  Account: {
    icon: `https://leapassets.s3.ap-south-1.amazonaws.com/navbar/account.svg`,
    text: 'Account',
    links: [
      {
        text: 'Profile',
        link: navRoutes.IELTS_STUDENT_DASHBOARD,
        isTargetBlank: false,
      },
      {
        text: 'Log Out',
        link: '/api/logout',
        isTargetBlank: false,
      },
    ],
  },
};

export const menaDropDown = [
  { id: 1, heading: 'Dashboard', value: menaLoggInStickyLinks },
  { id: 2, heading: 'My Account', value: menaAccountLink['Account'].links },
];
export const contactInfo = [
  {
    text: '(080) 47184844',
    link: 'tel:+918047184844',
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/call.svg',
    contentName: 'Phone',
  },
  {
    text: 'contact@leapscholar.com',
    link: 'mailto:contact@leapscholar.com',
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/email.svg',
    contentName: 'Email',
  },
];

export const singleLinks = [
  {
    text: 'Events',
    link: '/events',
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/event.svg',
  },
  {
    text: 'Blogs',
    link: '/blog',
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/library_books.svg',
  },
  {
    text: 'About',
    link: '/about-us',
    icon: 'https://leapassets.s3.ap-south-1.amazonaws.com/navbar/about.svg',
  },
];

export const loggenInStickyLinks = [
  {
    text: 'Counselling Dashboard',
    link: '/profile/counselling/overview',
  },
  {
    text: 'IELTS Prep Dashboard',
    link: '/test-prep/dashboard/',
  },
];
