import { FunctionComponent } from 'react';

const FooterHtmlSiteMap: FunctionComponent = () => {
  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(97 + i)?.toLowerCase(),
  );
  alphabet.push('others');

  return (
    <div className="max-w-[1180px] md:m-auto mt-[40px] md:mt-[40px] flex flex-col md:flex-row justify-around md:gap-[80px] border-t-[0.5px] border-t-[#686868] py-4 my-6 mb-[80px] md:mb-0 text-[14px] leading-[21px] font-bold">
      <div className="mb-4 md:mb-0">Universities</div>

      <div className="flex flex-wrap gap-[12px] md:gap-0 justify-evenly w-full">
        {alphabet?.map((word: string, idx) => {
          return (
            <>
              <a
                href={`${
                  process.env.DOMAIN_NAME ||
                  process.env.NEXT_PUBLIC_DOMAIN_NAME ||
                  'https://leapscholar.com'
                }/html-sitemap/abroad-university-list/${word}`}
                target="_self"
                key={idx}
              >
                {word?.toLocaleUpperCase()}
              </a>
              <span
                style={{
                  borderRight:
                    word?.length === 1 ? '1px solid white !important' : '',
                }}
              />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default FooterHtmlSiteMap;
