import { useEffect, useState } from 'react';
import { cookiesConstant, getCookie } from 'utils/cookie-utiils';
import { useSelector } from 'react-redux';
import { State } from 'Interfaces/storeSchema';
import { getUserCountry, setCountryInCookies } from 'utils/countryUtils';
import useLogin from 'hooks/useLogin';
import { getTokenCookie } from 'lib/auth-cookies-new';
import { AuthToken, CountryDto } from '@leapfinance/auth-kit';

const useLocation = () => {
  const auth = useSelector((state: State) => state.auth.auth);
  const { loginStatus } = useLogin();
  const [ipCountry, setIpCountry] = useState<string | null>(
    getCookie(null, cookiesConstant.IP_COUNTRY_KEY) || null,
  );

  const getUserCountryDetails = async () => {
    try {
      const token = getTokenCookie();
      const auth = new AuthToken(token);
      const country = await getUserCountry(auth);
      setIpCountry(country);
      setCountryInCookies(country);
    } catch (e) {
      setIpCountry(CountryDto.INDIA);
    }
  };

  useEffect(() => {
    getUserCountryDetails();
  }, [loginStatus]);

  return { userCountry: ipCountry };
};

export default useLocation;
