import { CountryDto } from '@leapfinance/auth-kit';

export const internationalLoginObject = {
  title: 'Log into your IELTS dashboard',
  canChangeCountryCode: true,
  canClose: false,
  whiteListedCountries: [
    CountryDto.INDIA,
    CountryDto.BANGLADESH,
    CountryDto.UAE,
    CountryDto.BAHRAIN,
    CountryDto.KUWAIT,
    CountryDto.OMAN,
    CountryDto.QATAR,
    CountryDto.SAUDI,
  ],
};
