import api from 'services/api';
import { FunctionComponent, useState, useEffect } from 'react';

interface FooterLink {
  id: number;
  text: string;
  link: string;
}

interface Category {
  id: number;
  heading: string;
  created_at: string;
  updated_at: string;
  published_at: string;
  footerLinkList: FooterLink[];
}

const FooterLinks: FunctionComponent = () => {
  const [footerLink, setFooterLink] = useState<Category[][]>();
  const [openId, setOpenId] = useState<null | number>(null);

  const handleToggle = (idx: number) => {
    setOpenId((prev: any) => (prev !== idx ? idx : null));
    try {
      const footerLinkDetails = document.getElementById('footer-link-details');
      if (footerLinkDetails) {
        const detailsElements = footerLinkDetails.querySelectorAll('details');
        detailsElements.forEach((details) => {
          details.addEventListener('click', () => {
            detailsElements.forEach((otherDetails) => {
              if (otherDetails !== details) {
                otherDetails.removeAttribute('open');
              }
            });
          });
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchFooterLinks = async () => {
    const apiUrl = `/api/avataran-route/avataran_cf_cms?path=footer-links?_limit=-1`;
    try {
      const res = await api.get(apiUrl);
      if (res?.data) {
        const responseData = (res?.data?.length && res?.data) || [];
        const result: Category[][] = [];
        responseData?.forEach((_: Category, index: number) => {
          if (index % 4 === 0) {
            result.push(responseData.slice(index, index + 4));
          }
        });
        setFooterLink(result);
      } else
        return {
          success: false,
          data: null,
        };
    } catch (e: any) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchFooterLinks();
  }, []);

  return (
    <div id="footer-link-details" className="flex flex-col gap-6">
      <>
        {footerLink?.map((sectionItem: Category[], idx: number) => {
          return (
            <>
              <section
                className="md:grid grid-cols-4 flex flex-col gap-6"
                key={`section-footer-${idx}`}
              >
                {sectionItem?.map((footerLinkItem) => {
                  return (
                    <details
                      className="group"
                      key={`footer-link-${idx}`}
                      onClick={() => handleToggle(footerLinkItem?.id)}
                    >
                      <summary className="flex cursor-pointer justify-between">
                        <h5 className="font-bold text-[14px] leading-[21px]">
                          {footerLinkItem?.heading}
                        </h5>
                        <svg
                          className="flex-shrink-0 ml-1.5 w-5 h-5 transition duration-300 group-open:-rotate-180"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </summary>
                      <div className="md:hidden">
                        <h5 className="text-[#CBCAD5] font-bold text-[14px] leading-[21px] mt-6 md:mt-4 mb-3 md:mb-1">
                          {footerLinkItem?.heading}
                        </h5>
                        <ul>
                          {footerLinkItem?.footerLinkList?.map(
                            (linkItem: FooterLink, index: number) => (
                              <li
                                className={`text-[14px] leading-[21px] font-medium md:font-normal cursor-pointer ${
                                  footerLinkItem?.footerLinkList?.length !==
                                  index + 1
                                    ? 'mb-3'
                                    : 'mb-0'
                                }`}
                                key={`link-item-${index}`}
                              >
                                <a href={`${linkItem?.link}`}>
                                  {linkItem?.text}
                                </a>
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </details>
                  );
                })}
              </section>
              {openId !== null &&
                sectionItem?.some((e: any) => e?.id === openId) && (
                  <div className="md:block hidden">
                    {sectionItem?.map((item: any, id) => {
                      if (item?.id !== openId) {
                        return null;
                      }

                      return (
                        <section key={`footer-link-${id}`}>
                          <h5 className="text-[#CBCAD5] font-bold text-[14px] leading-[21px] my-1">
                            {item?.heading}
                          </h5>
                          <ul className="grid grid-cols-4 gap-3">
                            {item?.footerLinkList?.map(
                              (linkItem: FooterLink, index: number) => (
                                <li
                                  className={`text-[14px] leading-[21px] md:font-normal`}
                                  key={`footer-link-sec-${index}`}
                                >
                                  <a
                                    target="_self"
                                    href={`${linkItem?.link}`}
                                    className="cursor-pointer"
                                  >
                                    {linkItem?.text}
                                  </a>
                                </li>
                              ),
                            )}
                          </ul>
                        </section>
                      );
                    })}
                  </div>
                )}
            </>
          );
        })}
      </>
    </div>
  );
};

export default FooterLinks;
